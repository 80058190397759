import HeroImage from "../HeroImage/HeroImage";
import NewArrivals from "../NewArrivals/NewArrivals";
import Products from "../Products/Products";
import Quote from "../Quote/Quote";
import Wallpaper from "../Wallpaper/Wallpaper";


function Home({change, setChange}) {
    return (
          <>
            <HeroImage change={change} setChange={setChange}/>
            <NewArrivals />
            <Products change={change} setChange={setChange}/>
            <Wallpaper change={change} setChange={setChange}/>
            <Quote />
          </>
    );
  }
  
  export default Home;
  