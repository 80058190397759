import style from './ProductsComponent.module.css';
import { useState } from 'react';

function Product({defaultPath, hoverPath, title, subTitle, weight}) {
    var styles;
    styles = {
        backgroundImage: `url('../../../NewProductImages/${defaultPath}')`
    }
    return ( 
        <div className={style.product} >
        <div className={style.wrap}>
            {/* <img className={style.productImg} src={almond} alt="" /> */}
            <div className={style.productImg} style={styles}></div>
        </div>
        <p className={style.label}>{title}</p>
        <p className={style.subLabel}>{subTitle}</p>
        <p className={style.subLabel}>{weight}</p>
    </div>
    )
}

export default Product;