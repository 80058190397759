import style from './Products.module.css'; 
import Product from '../ProductsComponent/Product';
import { Link } from 'react-router-dom';

function Products({change, setChange}) {
    return (
      <div className={style.container}>
        <div className={style.texts}>
            <div className={style.lines}>
                <div className={style.line}></div>
                <h2 className={style.newArrivals}>Our Products</h2>
                <div className={style.line}></div>
            </div>
            <p>Discover the finest range of dried nuts & fruits, chosen to enhance and complement your offerings.</p>
            <p><Link to="products" className='allProducts' onClick={() => {setChange(1 - 0)}}>All Products</Link></p>
        </div>
        <div className={style.products}>
            <div className={style.productsWrapper}>
            <Product defaultPath="PremiumAlmondShell.jpeg" hoverPath="almond.jpg" title="Almond" subTitle="Almond Without Shell" weight="250 g" />
            <Product defaultPath="PremiumWalnut.jpeg" hoverPath="walnuts.jpg" title="Walnuts" subTitle="Walnuts" weight="250 g" />
            <Product defaultPath="PremiumDriedApricots.jpeg" hoverPath="apricots.jpg" title="Apricots" subTitle="Apricots" weight="250 g" />
            <Product defaultPath="RoastedPistachio.jpeg" hoverPath="pistachio.jpg" title="Pistachio" subTitle="Pistachio" weight="250 g" />
            <Product defaultPath="PremiumRoastedPeanut.jpeg" hoverPath="peanuts.jpg" title="Peanuts" subTitle="Peanuts" weight="250 g" />
            <Product defaultPath="PremiumMixNuts.jpeg" hoverPath="pea.jpg" title="Premium Mix Nuts" subTitle="Mix Nuts" weight="250 g" />
            </div>
        </div>
      </div>
    );
  }
  
  export default Products;