import { useState } from 'react';
import Product from '../ProductsComponent/Product';

function ProductPage() {
    return (
        <div className="container-fluid mb-5">
            <h1 className="my-5">Products</h1>
            <div className="productsPageWrapper gy-3">
            <div className="">
    <Product defaultPath="BlackRaisin.jpeg" title="Black Raisin" subTitle="Raisin" weight="250 g" />
</div>
<div className="">
    <Product defaultPath="PremiumDriedFigs.jpeg" title="Dried Figs" subTitle="Premium Figs" weight="250 g" />
</div>
<div className="">
    <Product defaultPath="BlackRaisin2.jpeg" title="Black Raisin" subTitle="Raisin" weight="250 g" />
</div>
<div className="">
    <Product defaultPath="PremiumGoldenRaisin.jpeg" title="Golden Raisin" subTitle="Raisin" weight="250 g" />
</div>
<div className="">
    <Product defaultPath="DriedApricots.jpeg" title="Dried Apricots" subTitle="Apricots" weight="250 g" />
</div>
<div className="">
    <Product defaultPath="PremiumMixNuts.jpeg" title="Mix Nuts" subTitle="Premium Nuts" weight="250 g" />
</div>
<div className="">
    <Product defaultPath="DriedMulberry.jpeg" title="Dried Mulberry" subTitle="Mulberry" weight="250 g" />
</div>
<div className="">
    <Product defaultPath="PremiumRoastedAlmond.jpeg" title="Roasted Almond" subTitle="Premium Almond" weight="250 g" />
</div>
<div className="">
    <Product defaultPath="GoldenRaisin.jpeg" title="Golden Raisin" subTitle="Raisin" weight="250 g" />
</div>
<div className="">
    <Product defaultPath="PremiumRoastedCashew.jpeg" title="Roasted Cashew" subTitle="Premium Cashew" weight="250 g" />
</div>
<div className="">
    <Product defaultPath="PremimumChiliRoastedPistachio.jpeg" title="Chili Roasted Pistachio" subTitle="Premium Pistachio" weight="250 g" />
</div>
<div className="">
    <Product defaultPath="PremiumRoastedPeanut.jpeg" title="Roasted Peanut" subTitle="Premium Peanut" weight="250 g" />
</div>
<div className="">
    <Product defaultPath="PremiumAlmondShell.jpeg" title="Almond" subTitle="Almond Without Shell" weight="250 g" />
</div>
<div className="">
    <Product defaultPath="PremiumBlackRaisin.jpeg" title="Black Raisin" subTitle="Premium Raisin" weight="250 g" />
</div>
<div className="">
    <Product defaultPath="PremiumChiliCashew.jpeg" title="Chili Cashew" subTitle="Premium Cashew" weight="250 g" />
</div>
<div className="">
    <Product defaultPath="PremiumDriedApricots.jpeg" title="Dried Apricots" subTitle="Premium Apricots" weight="250 g" />
</div>
<div className="">
    <Product defaultPath="PremiumDriedApricots2.jpeg" title="Dried Apricots" subTitle="Premium Apricots" weight="250 g" />
</div>
<div className="">
    <Product defaultPath="PremiumDriedApricots3.jpeg" title="Dried Apricots" subTitle="Premium Apricots" weight="250 g" />
</div>
<div className="">
    <Product defaultPath="PremiumDriedApricotsSeeds.jpeg" title="Dried Apricots with Seeds" subTitle="Premium Apricots" weight="250 g" />
</div>
<div className="">
    <Product defaultPath="RedWalnut.jpeg" title="Red Walnut" subTitle="Walnut" weight="250 g" />
</div>
<div className="">
    <Product defaultPath="RoastedPistachio.jpeg" title="Roasted Pistachio" subTitle="Premium Pistachio" weight="250 g" />
</div>
<div className="">
    <Product defaultPath="RowCashew.jpeg" title="Raw Cashew" subTitle="Cashew" weight="250 g" />
</div>
<div className="">
    <Product defaultPath="SugarCoatedAlmonds.jpeg" title="Sugar Coated Almonds" subTitle="Premium Almonds" weight="250 g" />
</div>
<div className="">
    <Product defaultPath="ZatarPistachio.jpeg" title="Zatar Pistachio" subTitle="Pistachio" weight="250 g" />
</div>
            </div>
        </div>
    );
}

export default ProductPage;