import style from './Footer.module.css';

function Footer() {
  return (
        <div className={style.Footer}>
            All Rights Reserved - TJ Garden
        </div>
  );
}

export default Footer;
