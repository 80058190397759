import style from './Quote.module.css';

function Quote() {
  return (
        <div className={style.Quote}>
            <em>From nature to your shelves,
            <br />delivering quality in every bite.</em>
        </div>
  );
}

export default Quote;
