import style from './HeroImage.module.css'
import hero from './background.webp';
import almonds from './almonds-transparent.png'
import { Link } from 'react-router-dom';

function HeroImage({change, setChange}) {
    return (
      <div className="container heroImg shadow">
        <div className="heroText">
          <h1>Elevate Your Offerings <br /> with Natural Goodness!</h1>
          <p>Hand-picked dried nuts & fruits, perfect for your product line.</p><p>Freshness and taste in every bite.</p>
          <Link className='btn' to="products" onClick={() => {setChange(1 - change)}}>Explore Now</Link>
        </div>
        <img src={almonds} alt="" />
      </div>
      // <div className="container-fluid mb-5">
      //   <div className="hero">
      //       <div className="heroText">
      //           <h1>Elevate Your Offerings <br /> with Natural Goodness!</h1>
      //           <p>Hand-picked dried nuts & fruits, perfect for your product line.</p><p>Freshness and taste in every bite.</p>
      //           <a href="#" className="btn">Explore Now</a>
      //       </div>
      //   </div>
      // </div>
    );
  }
  
  export default HeroImage;