import Header from "./components/Header/Header";
import { useState } from 'react';
import './App.css';
import HeroImage from "./components/HeroImage/HeroImage";
import Products from "./components/Products/Products";
import Wallpaper from "./components/Wallpaper/Wallpaper";
import Quote from "./components/Quote/Quote";
import Footer from "./components/Footer/Footer";
import NewArrivals from "./components/NewArrivals/NewArrivals";
import ProductPage from "./components/ProductsPage/ProductsPage";
import About from "./components/About/About";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./components/Home/Home";


function App() {
  const [page, setPage] = useState('products');
  const [change, setChange] = useState(0);

  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Header change={change} setChange={setChange}/>}>
            <Route index element={<Home change={change} setChange={setChange}/>} />
            <Route path="products" element={<ProductPage change={change} setChange={setChange}/>} />
            <Route path="about" element={<About change={change} setChange={setChange}/>} />
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
