import raisins from './PremiumGoldenRaisin.jpeg';
import walnuts from './RedWalnut.jpeg';
import pistachio from './ZatarPistachio.jpeg';

function NewArrivals() {
    return (
        <div className="container-fluid mb-5 newArrivalsWrapper">
            <div className="texts">
                <div className="lines">
                    <div className="line"></div>
                    <h2 className="newArrivals">New Arrivals</h2>
                    <div className="line"></div>
                </div>
                <p>New arrivals of the season’s best dried nuts & fruits, ready to elevate your offerings.</p>
            </div>
            <div className="container newArrivalContainer">
                <div className="newArrivalProduct">
                    <img src={raisins} alt="" />
                </div>
                <div className="newArrivalProduct">
                    <img src={walnuts} alt="" />
                </div>
                <div className="newArrivalProduct">
                    <img src={pistachio} alt="" />
                </div>
            </div>
            <div id="carouselExampleIndicators" class="carousel slide newArrivalsCarousel" data-bs-ride="carousel">
  <div class="carousel-indicators">
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>
  </div>
  <div class="carousel-inner">
    <div class="carousel-item active">
      <img src={raisins} class="d-block w-100" alt="..." />
    </div>
    <div class="carousel-item">
      <img src={walnuts} class="d-block w-100" alt="..." />
    </div>
    <div class="carousel-item">
      <img src={pistachio} class="d-block w-100" alt="..." />
    </div>
  </div>
  <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Previous</span>
  </button>
  <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
    <span class="carousel-control-next-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Next</span>
  </button>
</div>
        </div>
    );
  }
  
  export default NewArrivals;