import img1 from '../../images/CarouselImages/img1.jpeg';

function About() {
    function importAll(r) {
        let images = {};
        r.keys().map(item => { images[item.replace('./', '')] = r(item); });
        return images;
    }
    
    const images = importAll(require.context('../../images/CarouselImages', true));
    var keys = Object.keys(images);
    var size = keys.length;
    var keys1 = Object.keys(images).slice(0, 5);
    var keys2 = Object.keys(images).slice(5);
    console.log(keys1);
    console.log(keys2);
  return (
        <div className="container mb-5">
            <h1 className="my-5">About Us</h1>
            <div className="sliderWrapper mb-5 rounded">
                <div id="carouselExampleCaptions" class="carousel slide rounded" data-bs-ride="carousel">
                    <div class="carousel-indicators">
                        <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
                        <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="1" aria-label="Slide 2"></button>
                        <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="2" aria-label="Slide 3"></button>
                        <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="3" aria-label="Slide 3"></button>
                        <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="4" aria-label="Slide 3"></button>
                    </div>
                    <div class="carousel-inner">
                        {keys1.map((name, index) => {
                            var active = "";
                            if ( index == 0 ) {
                                active = 'active';
                            }
                        return (<div class={"carousel-item " + active}>
                            <img src={images[name]} class="d-block w-100 rounded" alt="..." />
                            </div>)
                        })}
                        {/* <div class="carousel-item active">
                        <img src={images['img1.jpeg']} class="d-block w-100 rounded" alt="..." />
                        </div>
                        <div class="carousel-item">
                        <img src={images['img2.jpeg']} class="d-block w-100 rounded" alt="..." />
                        </div>
                        <div class="carousel-item">
                        <img src={images['img3.jpeg']} class="d-block w-100 rounded" alt="..." />
                        </div> */}
                    </div>
                    <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
                        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span class="visually-hidden">Previous</span>
                    </button>
                    <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
                        <span class="carousel-control-next-icon" aria-hidden="true"></span>
                        <span class="visually-hidden">Next</span>
                    </button>
                </div>
                <div className="text">
                    <h1 className='heading'>Welcome to TJ Gardens</h1>
                    <h5 className='subheading'>Your Source for Premium Organic Nuts and Dried Fruits</h5>
                    <p className='paragraph'>At TJ Garden, we are proud to be a leading supplier of high-quality, fresh, organic nuts and dried fruits sourced from the best farms around the world. Our focus on excellence ensures that each product meets the highest standards of freshness, flavor, and nutrition.</p>
                    <br />
                    <h5 className='heading2'>Wide Range of Organic Products</h5>
                    <p className='paragraph'>We offer a variety of nutrient-packed nuts and delicious dried fruits, including almonds, cashews, pistachios, walnuts, dried apricots, figs, raisins, and more. Whether you're looking for a healthy snack or premium ingredients for your meals, TJ Garden has the perfect selection for you.</p>
                </div>
            </div>
            <br />
            <div className="sliderWrapper sliderWrapper2 mb-5 rounded">
                <div className="text">
                    <h5 className='heading2'>Certified Organic, 100% Natural</h5>
                    <p>At TJ Garden, our commitment to quality is reflected in our certifications and strict adherence to international standards. Our products are:</p>
                    <ul>
                        <li>USDA Organic</li>
                        <li>EU Organic Certified</li>
                        <li>ISO 22000</li>
                        <li>HACCP Certified</li>
                    </ul>
                    <p>Each certification ensures that our products are grown, processed, and delivered with care, offering you pure, chemical-free, and sustainably sourced products.</p>
                    <br />
                    <h5 className='heading2'>Dedicated to Freshness, Sustainability, and Health</h5>
                    <p className='paragraph'>Based in Dubai, UAE, TJ Garden is committed to providing fresh, responsibly sourced products that contribute to a healthier lifestyle. From nutritious snacks to premium ingredients, our organic nuts and dried fruits are a testament to our unwavering dedication to quality, sustainability, and health.</p>
                </div>
                <div id="carouselExampleCaptions2" class="carousel slide rounded" data-bs-ride="carousel">
                    <div class="carousel-indicators">
                        <button type="button" data-bs-target="#carouselExampleCaptions2" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
                        <button type="button" data-bs-target="#carouselExampleCaptions2" data-bs-slide-to="1" aria-label="Slide 2"></button>
                        <button type="button" data-bs-target="#carouselExampleCaptions2" data-bs-slide-to="2" aria-label="Slide 3"></button>
                        <button type="button" data-bs-target="#carouselExampleCaptions2" data-bs-slide-to="3" aria-label="Slide 3"></button>
                        <button type="button" data-bs-target="#carouselExampleCaptions2" data-bs-slide-to="4" aria-label="Slide 3"></button>
                    </div>
                    <div class="carousel-inner">
                        {keys2.map((name, index) => {
                            var active = "";
                            if ( index == 0 ) {
                                active = 'active';
                            }
                        return (<div class={"carousel-item " + active}>
                            <img src={images[name]} class="d-block w-100 rounded" alt="..." />
                            </div>)
                        })}
                        {/* <div class="carousel-item active">
                        <img src={images['img1.jpeg']} class="d-block w-100 rounded" alt="..." />
                        </div>
                        <div class="carousel-item">
                        <img src={images['img2.jpeg']} class="d-block w-100 rounded" alt="..." />
                        </div>
                        <div class="carousel-item">
                        <img src={images['img3.jpeg']} class="d-block w-100 rounded" alt="..." />
                        </div> */}
                    </div>
                    <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions2" data-bs-slide="prev">
                        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span class="visually-hidden">Previous</span>
                    </button>
                    <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions2" data-bs-slide="next">
                        <span class="carousel-control-next-icon" aria-hidden="true"></span>
                        <span class="visually-hidden">Next</span>
                    </button>
                </div>
            </div>
            <br />
        </div>
  );
}

export default About;
