import style from './Wallpaper.module.css';

function Wallpaper() {
  return (
        <div className={style.wallpaper}>
            Read About Us
        </div>
  );
}

export default Wallpaper;
